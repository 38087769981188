import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'search', 'dropdown', 'results', 'hidden'];

  static values = {
    collection: Array,
    required: Boolean,
    selected: Array,
  };

  declare readonly inputTarget: HTMLInputElement;

  declare readonly searchTarget: HTMLInputElement;

  declare readonly dropdownTarget: HTMLDivElement;

  declare readonly resultsTarget: HTMLElement;

  declare readonly hiddenTarget: HTMLInputElement;

  declare collectionValue: SearchableItem[];

  declare requiredValue: boolean;

  declare selectedValue: number[];

  declare isMouseDown: boolean;

  connect() {
    this.isMouseDown = false;
    this.inputTarget.required = this.requiredValue;

    this.resetResults();
    this.setInitialValue();
    this.registerEventListeners();
  }

  disconnect() {
    this.searchTarget.removeEventListener("blur", this.onInputBlur);
  }

  open() {
    if (this.resultsShown) return;

    this.resultsShown = true;
    this.element.setAttribute("aria-expanded", "true");
    this.searchTarget.focus();
  }

  close() {
    if (!this.resultsShown) return;

    this.resultsShown = false;
    this.element.setAttribute("aria-expanded", "false");
    this.searchTarget.removeAttribute("aria-activedescendant");
    this.searchTarget.value = "";
    this.resetResults();
  }

  protected filterResults() {
    const query = this.searchTarget.value.trim();

    if (query) {
      const results = this.fetchResults(query);
      results.length ? this.populateResults(results) : this.displayNoResults();
    } else {
      this.resetResults();
    }
  }

  private fetchResults(query: string): SearchableItem[] {
    return this.collectionValue.filter(item => {
      const regex = new RegExp(query, "gi");
      return item.text.match(regex);
    });
  }

  protected resetResults() {
    this.populateResults(this.collectionValue);
  }

  private setInitialValue() {
    const initialValues = this.options.filter(item => this.selectedValue.includes(parseInt(item.getAttribute("data-search-input-value"), 10)));
    initialValues.forEach(item => {
      this.commit(item);
    });
  }

  private select(target: Element) {
    const previouslySelected = this.selectedOption;

    if (previouslySelected) {
      previouslySelected.removeAttribute("aria-selected");
      previouslySelected.classList.remove(...this.selectedClasses);
    }

    target.setAttribute("aria-selected", "true");
    target.classList.add(...this.selectedClasses);
    this.searchTarget.setAttribute("aria-activedescendant", target.id);
    target.scrollIntoView({ behavior: "smooth", block: "nearest" });
  }

  protected commit(selected: Element) {
    const textValue = selected.textContent.trim();
    const value = selected.getAttribute("data-search-input-value");

    this.inputTarget.value = textValue;
    this.hiddenTarget.value = value;

    this.close();
  }

  private sibling(next: boolean): Element {
    const options = this.options;
    const selected = this.selectedOption;
    const index = options.indexOf(selected);
    const sibling = next ? options[index + 1] : options[index - 1];
    const firstOrLast = next ? options[0] : options[options.length - 1];
    return sibling || firstOrLast;
  }

  protected populateResults(collection: SearchableItem[]) {
    this.resultsTarget.innerHTML =
      collection.map(item => `<li data-search-input-value="${item.value}" role="option" class="py-1 px-4 hover:bg-gray-200 cursor-pointer">${item.text}</li>`).join('');
  }

  private displayNoResults() {
    this.resultsTarget.innerHTML = '<div class="px-4 py-2">No results found.</div>';
  }

  private registerEventListeners() {
    this.searchTarget.addEventListener("keydown", this.onKeydown);
    this.searchTarget.addEventListener("blur", this.onInputBlur);
    this.searchTarget.addEventListener("input", this.onInputChange);

    this.resultsTarget.addEventListener("mousedown", this.onResultsMouseDown);
    this.resultsTarget.addEventListener("click", this.onResultsClick);
  }

  get resultsShown() {
    return !this.dropdownTarget.hidden;
  }

  set resultsShown(value) {
    this.dropdownTarget.hidden = !value;
  }

  get options() {
    return Array.from(this.resultsTarget.querySelectorAll('[role="option"]'));
  }

  get selectedOption() {
    return this.resultsTarget.querySelector('[aria-selected="true"]');
  }

  get selectedClasses() {
    return ["bg-gray-200"];
  }

  onKeydown = (event: KeyboardEvent) => {
    const key = `on${event.key}Keydown` as KeyboardEvents;
    const handler = this[key];
    if (handler) handler(event);
  }

  onEscapeKeydown = (event: KeyboardEvent) => {
    if (!this.resultsShown) return;

    this.close();
    event.stopPropagation();
    event.preventDefault();
  }

  onArrowDownKeydown = (event: KeyboardEvent) => {
    const item = this.sibling(true);
    if (item) this.select(item);
    event.preventDefault();
  }

  onArrowUpKeydown = (event: KeyboardEvent) => {
    const item = this.sibling(false);
    if (item) this.select(item);
    event.preventDefault();
  }

  onTabKeydown = () => {
    const selected = this.selectedOption;
    if (selected) this.commit(selected);
  }

  onEnterKeydown = (event: KeyboardEvent) => {
    const selected = this.selectedOption;
    if (selected && this.resultsShown) {
      this.commit(selected);
      event.preventDefault();
    }
  }

  onResultsClick = (event: MouseEvent) => {
    if (!(event.target instanceof Element)) return;
    const selected = event.target.closest('[role="option"]');
    if (selected) {
      this.commit(selected);
      this.filterResults();
    }
  }

  onResultsMouseDown = () => {
    this.isMouseDown = true;
    this.resultsTarget.addEventListener("mouseup", () => {
      this.isMouseDown = false;
    }, { once: true });
  }

  onInputBlur = () => {
    if (this.isMouseDown) return;
    this.close();
  }

  onInputChange = () => {
    this.open();
    this.element.removeAttribute("value");
    this.hiddenTarget.value = "";
    this.filterResults();
  }
}
