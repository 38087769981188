import { Controller } from '@hotwired/stimulus';
import { KEYBOARD_KEYS } from '../constants';
import { FileSizeValidator } from '../utils';

export default class extends Controller {
  static targets = [
    'input',
    'wrapper',
    'removePhotoLink',
    'validationOutput',
    'label',
  ];

  static values = { valid: Boolean };

  declare validationMessage: string;

  declare submitButton: HTMLButtonElement;

  declare readonly inputTarget: HTMLInputElement;

  declare readonly validationOutputTarget: HTMLParagraphElement;

  declare readonly wrapperTarget: HTMLDivElement;

  declare readonly labelTarget: HTMLLabelElement;

  declare readonly removePhotoLinkTarget: HTMLAnchorElement;

  declare readonly hasRemovePhotoLinkTarget: boolean;

  declare validValue: boolean;

  declare fileSizeValidator: FileSizeValidator;

  private timeout: Timeout;

  public connect() {
    this.submitButton = this.inputTarget.form.querySelector('button[type="submit"]');
  }

  public handleInvalid(event: ListenerEvent) {
    event.preventDefault();
    this.validValue = false;
  }

  public validValueChanged() {
    this.wrapperTarget.classList.toggle('shadow-danger', !this.validValue);
    this.validationOutputTarget.innerHTML = this.validValue
      ? ''
      : this.getValidationMessage();
  }

  public handleKeyPress(event: ListenerEvent<HTMLLabelElement>) {
    if (event.key !== KEYBOARD_KEYS.SPACE) return;

    event.preventDefault();
    this.inputTarget.click();
  }

  public handleFocusOrBlur({ type }: ListenerEvent) {
    if (!this.hasRemovePhotoLinkTarget) return;

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (document.activeElement !== this.removePhotoLinkTarget) {
        this.removePhotoLinkTarget.classList.toggle('hidden', type === 'blur');
        this.removePhotoLinkTarget.classList.toggle('flex', type === 'focus');
      }
    });
  }

  public handleInputChange(event: ListenerEvent<HTMLInputElement>) {
    const [file] = event.currentTarget.files;
    const url = URL.createObjectURL(file);

    this.fileSizeValidator = new FileSizeValidator(file);
    this.wrapperTarget.style.backgroundImage = `url(${url})`;
    this.setLabelState();
    this.labelTarget.blur();

    if (this.fileSizeValidator.validate()) {
      this.validationMessage = this.fileSizeValidator.message();
      this.validValue = false;
      this.validValueChanged();
    } else {
      this.validValue = this.inputTarget.checkValidity();
    }

    this.submitButton.disabled = !this.validValue;
  }

  private getValidationMessage() {
    return this.validationMessage || this.inputTarget.validationMessage;
  }

  private setLabelState() {
    const { files } = this.inputTarget;
    this.labelTarget.classList.toggle('text-transparent', !!files.length);
    const star = this.labelTarget.querySelector('span[class*=text-danger]');

    if (!star) return;

    star.classList.toggle('text-danger', !files.length);
    star.classList.toggle('text-transparent', !!files.length);
  }
}
