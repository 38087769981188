import { Controller } from '@hotwired/stimulus';
import UrlWithParams from '../utils/url_with_params';

export default class extends Controller {
  static targets = ['frame'];

  static values = { url: String };

  declare readonly frameTarget: HTMLElement;

  declare readonly urlValue: string;

  private urlWithParams: UrlWithParams;

  connect() {
    this.urlWithParams = new UrlWithParams(this.urlValue);
  }

  private setSource() {
    this.frameTarget.setAttribute('src', this.urlWithParams.urlString());
  }

  public handleInputChange({
    currentTarget: { name, value },
  }: ListenerEvent<FormControl>) {
    this.urlWithParams.append(name, value);
    this.setSource();
  }

  public handleCheckboxChange({
    currentTarget: { name, checked },
  }: ListenerEvent<HTMLInputElement>) {
    this.urlWithParams.set(name, String(checked));
    this.setSource();
  }
}
